var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"row-key":"id","data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"width":"80","prop":"goods_id","label":"商品编号"}}),_c('el-table-column',{attrs:{"width":"80","prop":"id","label":"记录编号"}}),_c('el-table-column',{attrs:{"prop":"version","label":"版本号"}}),_c('el-table-column',{attrs:{"prop":"time","label":"时间"}}),_c('el-table-column',{attrs:{"label":"创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_vm._v(_vm._s(_vm._f("date")(s.row.created_at)))]}}])}),_c('el-table-column',{attrs:{"label":"最后编辑时间"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_vm._v(_vm._s(_vm._f("date")(s.row.updated_at)))]}}])}),_c('el-table-column',{attrs:{"fixed":"right","width":"145","align":"center","label":"操作"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.$router.push({
          name:'shopGoodsUpgradeLogEdit',
          query:{
            goods_id:parseInt(_vm.$route.query.goods_id)
          }
          })}}},[_vm._v("添加")])]},proxy:true},{key:"default",fn:function(s){return [_c('div',[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.$router.push({
          name:'shopGoodsUpgradeLogEdit',
          query:{
            id:s.row.id,
            goods_id:parseInt(_vm.$route.query.goods_id)
          }
          })}}},[_vm._v("编辑 ")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(s.row.id)}}},[_vm._v("移除")])],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }