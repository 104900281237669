<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table row-key="id" :data="list">
        <el-table-column width="80" prop="goods_id" label="商品编号"></el-table-column>
        <el-table-column width="80" prop="id" label="记录编号"></el-table-column>
        <el-table-column prop="version" label="版本号"></el-table-column>
        <el-table-column prop="time" label="时间"></el-table-column>
        <el-table-column label="创建时间">
          <template v-slot="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column label="最后编辑时间">
          <template v-slot="s">{{s.row.updated_at | date}}</template>
        </el-table-column>
        <el-table-column fixed="right" width="145" align="center" label="操作">
          <template #header>
            <el-button @click="$router.push({
            name:'shopGoodsUpgradeLogEdit',
            query:{
              goods_id:parseInt($route.query.goods_id)
            }
            })" size="mini" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <div>
              <el-button @click="$router.push({
            name:'shopGoodsUpgradeLogEdit',
            query:{
              id:s.row.id,
              goods_id:parseInt($route.query.goods_id)
            }
            })" size="mini" type="primary">编辑
              </el-button>
              <el-button @click="del(s.row.id)" size="mini" type="danger">移除</el-button>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(id) {
      this.$u.api.shop.goods.upgradeLogDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.shop.goods.upgradeLog({
        page:this.page,
        goods_id:parseInt(this.$route.query.goods_id)
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>